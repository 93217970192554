// reducer
import rootReducer from "./rootReducer"

// ** Redux Imports
import { configureStore } from "@reduxjs/toolkit"
import { setupListeners } from "@reduxjs/toolkit/query"
import { adminApi } from "./admin/admin.redux"
import { adminCompanyApi } from "./admin/company"
import { authApi } from "./auth/authService"
import { companyApi } from "./company/company_service"
import { educationsApi } from "./education/educations"
import { employerAuthApi } from "./employer/auth"
import { postalApi } from "./employer/postal_service"
import { employerProfileApi } from "./employer/profile"
import { jobApplyApi } from "./job/job_apply"
import { jobBookmarkApi } from "./job/job_bookmark"
import { jobCategoriesApi } from "./job/job_categories"
import { jobIndustriesApi } from "./job/job_industries"
import { jobPostApi } from "./job/job_post"
import { jobSeekerApi } from "./job/job_seeker"
import { jobTypeApi } from "./job/job_type"
import { jobApplicationApi } from "./jobApplication/jobApplicationService"
import { jobStatsApi } from "./job/job_stats"
import { candidatesApi } from "./employer/candidate"
import { adminJobPostApi } from "./jobPost/jobPostService"
import { roleApi } from "./rolepermission/roleService"
import { seekerAuthApi } from "./seeker/auth"
import { seekerProfileApi } from "./seeker/profile"
import { seekerDocumentApi } from "./seeker/seekerDocument"
import { seekerEducationsApi } from "./seeker/seeker_education"
import { seekerExperienceApi } from "./seeker/seeker_experience"
import { seekerJapanExperienceApi } from "./seeker/seeker_japan_experience"
import { seekerLanguageApi } from "./seeker/seeker_language"
import { seekerLanguagesApi } from "./seeker/seeker_languages"
import { seekerQualificationApi } from "./seeker/seeker_qualification"
import { chatHandle } from "./chat/chatHandle"
import { subscriptionsApi } from "./subscriptions/subscriptions"
import { changePasswordApi } from "./admin/changePassword"
import { adminSubscriptionsApi } from "./admin/subscribe"
import { subscriptionsPricesApi } from "./subscriptions/subscriptionPrices"
import { seekerCartApi } from "./seeker/seekerCart"
import { seekerSubscriptionsApi } from "./seeker/seekerSubscription"

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false,
    }).concat(
      authApi.middleware,
      adminApi.middleware,
      adminCompanyApi.middleware,
      jobCategoriesApi.middleware,
      jobTypeApi.middleware,
      jobIndustriesApi.middleware,
      educationsApi.middleware,
      seekerAuthApi.middleware,
      seekerProfileApi.middleware,
      seekerEducationsApi.middleware,
      seekerExperienceApi.middleware,
      seekerJapanExperienceApi.middleware,
      seekerLanguageApi.middleware,
      seekerQualificationApi.middleware,
      seekerLanguagesApi.middleware,
      educationsApi.middleware,
      seekerDocumentApi.middleware,
      jobPostApi.middleware,
      jobApplyApi.middleware,
      seekerDocumentApi.middleware,
      employerAuthApi.middleware,
      employerProfileApi.middleware,
      postalApi.middleware,
      companyApi.middleware,
      roleApi.middleware,
      adminJobPostApi.middleware,
      jobBookmarkApi.middleware,
      jobSeekerApi.middleware,
      jobPostApi.middleware,
      jobApplicationApi.middleware,
      jobStatsApi.middleware,
      chatHandle.middleware,
      candidatesApi.middleware,
      subscriptionsApi.middleware,
      changePasswordApi.middleware,
      adminSubscriptionsApi.middleware,
      subscriptionsPricesApi.middleware,
      seekerCartApi.middleware,
      seekerSubscriptionsApi.middleware
    )
  },
})

setupListeners(store.dispatch)
export { store }
