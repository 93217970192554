import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { baseUrlConfig, authHeader } from "../../helpers/http"

export const seekerSubscriptionsApi = createApi({
  reducerPath: "seekerSubscriptions",
  baseQuery: fetchBaseQuery(baseUrlConfig),
  tagTypes: ["seekerSubscriptions"],
  endpoints: (builder) => ({
    getSubscriptions: builder.mutation({
      query: () => ({
        url: "/seeker/subscriptions",
        headers: authHeader(),
        method: "get",
      }),
      transformResponse: (response) => response.results,
    }),
    getSubscriptionsById: builder.mutation({
      query: (id) => ({
        url: `/seeker/subscriptions/${id}`,
        headers: authHeader(),
        method: "get",
      }),
      transformResponse: (response) => {
        return response.data
      },
    }),
  }),
})

export const { useGetSubscriptionsMutation, useGetSubscriptionsByIdMutation } =
  seekerSubscriptionsApi
