import "@src/assets/css/chat.css"
import { Minimize2 } from "react-feather"
import { Button } from "reactstrap"

export const HeaderPeople = ({ onChangeText, placeholder = 'Cari Nama', onClick, isMobile }) => {
    const _onChange = (e) => {
        onChangeText && onChangeText(e.target.value)
    }

    return (
        <div className=" pb-1 px-1 header-chat-contact">
            <div className="flex align-items-center justify-content-between">
                <h4 className="py-1 mb-0">Chat</h4>
                {isMobile && (
                    <div>
                        <Button color="danger" size="sm" onClick={onClick}>
                            <Minimize2 size={16} />
                        </Button>
                    </div>
                )}
            </div>
            <div className="input-group input-group-sm">
                <input
                    type="text"
                    className="form-control search-chat"
                    aria-label="Sizing example input"
                    aria-describedby="inputGroup-sizing-sm"
                    placeholder={placeholder}
                    onChange={_onChange}
                />
            </div>

        </div>
    )
}