import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { baseUrlConfig, authHeader } from "../../helpers/http"

export const subscriptionsPricesApi = createApi({
    reducerPath: "subscriptionsPrices",
    baseQuery: fetchBaseQuery(baseUrlConfig),
    tagTypes: ["subscriptionsPrices"],
    endpoints: (builder) => ({
        getSubscriptions: builder.mutation({
            query: () => ({
                url: "/subscriptions/prices",
                method: "get",
            }),
            transformResponse: (response) => response.results,
        }),
        createPendingSubscription: builder.mutation({
            query: (body) => ({
                url: '/seeker/subscriptions',
                method: "post",
                headers: authHeader(),
                body,
            }),
            transformResponse: (response) => response.results,
        })
    }),
})

export const { useGetSubscriptionsMutation, useCreatePendingSubscriptionMutation } = subscriptionsPricesApi