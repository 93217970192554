import "@src/assets/css/chat.css"
import { ChevronLeft, Minimize2 } from "react-feather"
import { Button } from "reactstrap"
import Avatar from '@src/assets/images/chat/avatar_chat.jpg'

export const HeaderBody = ({ onClick, name, status = false, imgUrl, isMobile, onBackClick }) => {

    return (
        <header className="header-chat-contact d-flex justify-content-between align-items-center py-1 z-3 px-1">
            <div className="d-flex align-items-center">
                <div className="d-flex gap-1 align-items-center">
                    {isMobile && (
                        <Button color="danger" size="sm" onClick={onBackClick}>
                            <ChevronLeft size={16} />
                        </Button>
                    )}
                    <img
                        src={imgUrl || Avatar}
                        className="rounded-circle profile-chat-pv"
                        alt="profil"
                    />
                </div>
                <div>
                    <h5 className="mb-0">{name}</h5>
                    <p className="indicator-chat mb-0" style={{
                    }}>{status ? 'Online' : 'Offline'}</p>
                </div>
            </div>

            <div>
                <Button color="danger" size="sm" onClick={onClick}>
                    <Minimize2 size={16} />
                </Button>
            </div>
        </header>
    )
}