import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { baseUrlConfig, authHeader } from '../../helpers/http'

export const seekerCartApi = createApi({
  reducerPath: 'seeker/cart',
  baseQuery: fetchBaseQuery(baseUrlConfig),
  tagTypes: ['SeekerCart'],
  endpoints: (builder) => ({
    getCart: builder.mutation({
      query: (id) => ({
        url: `/seeker/subscriptions/${id}`,
        method: 'GET',
        headers: authHeader()
      }),
      transformResponse: (response) => response?.data
    }),
    postCheckout: builder.mutation({
      query: (body = {}) => ({
        url: `/seeker/payments`,
        method: 'POST',
        headers: authHeader(),
        body
      }),
      transformResponse: (response) => {
        console.log('response post payment', response);
        return response?.data
      }
    })
  })
})

export const {
  useGetCartMutation,
  usePostCheckoutMutation
} = seekerCartApi
