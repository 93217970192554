import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { baseUrlConfig, authHeader } from '../../helpers/http'

const defaultPath = 'admin/subscriptions'

export const adminSubscriptionsApi = createApi({
  reducerPath: 'AdminSubscriptions',
  baseQuery: fetchBaseQuery(baseUrlConfig),
  tagTypes: ["AdminSubscriptions"],
  endpoints: (builder) => ({
    getAllSubscriptions: builder.mutation({
      query: () => ({
        url: defaultPath,
        method: 'get',
        headers: authHeader()
      }),
      providesTags: ['GetAllSubscriptions'],
      transformResponse: (response) => response.results
    }),
    getSubscriptionsByUserId: builder.mutation({
      query: (id) => ({
        url: `${defaultPath}/${id}`,
        method: 'get',
        headers: authHeader()
      }),
      providesTags: ['GetAllSubscriptionsByUserId'],
      transformResponse: (response) => response.results
    }),
    postSubscriptions: builder.mutation({
      query: ({ name, description, price }) => ({
        url: `${defaultPath}/prices`,
        method: 'post',
        headers: authHeader(),
        body: {
          name,
          description,
          price,
        }
      }),
      providesTags: ['PostSubscriptions'],
      transformResponse: (response) => response.results
    }),
    getSubscriptionsPrices: builder.mutation({
      query: () => ({
        url: `${defaultPath}/prices`,
        method: 'get',
        headers: authHeader()
      }),
      providesTags: ['GetSubscriptionsPrices'],
      transformResponse: (response) => response.results
    }),
    getSubscriptionsPriceByUserId: builder.mutation({
      query: (id) => ({
        url: `${defaultPath}/prices/${id}`,
        method: 'get',
        headers: authHeader()
      }),
      providesTags: ['GetSubscriptionsPricesByUserId'],
      transformResponse: (response) => response.results
    }),
    postSubscriptionsPrices: builder.mutation({
      query: ({ name, description, price, expired }) => ({
        url: `${defaultPath}/prices`,
        method: 'post',
        headers: authHeader(),
        body: {
          name,
          description,
          price: parseInt(price),
          expired
        }
      }),
      providesTags: ['PostSubscriptionsPrices'],
      transformResponse: (response) => response.results
    }),
    updateSubscriptionsPrices: builder.mutation({
      query: ({ id, name, description, price, expired }) => ({
        url: `${defaultPath}/prices/${id}/update`,
        method: 'put',
        headers: authHeader(),
        body: {
          name,
          description,
          price: parseInt(price),
          expired
        }
      }),
      providesTags: ['UpdateSubscriptionsPrices'],
      transformResponse: (response) => response.results
    })
  })
})

export const {
  useGetAllSubscriptionsMutation,
  useGetSubscriptionsByUserIdMutation,
  usePostSubscriptionsMutation,
  useGetSubscriptionsPricesMutation,
  useGetSubscriptionsPriceByUserIdMutation,
  usePostSubscriptionsPricesMutation,
  useUpdateSubscriptionsPricesMutation
} = adminSubscriptionsApi
