import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { authHeader, baseUrlConfig } from "../../helpers/http"

const baseUrl = 'employer/candidates'

export const candidatesApi = createApi({
    reducerPath: "employer",
    baseQuery: fetchBaseQuery(baseUrlConfig),
    tagTypes: ['employer'],
    endpoints: (builder) => ({
        getBestCandidates: builder.mutation({
            query: () => ({
                url: `${baseUrl}`,
                method: 'GET',
                headers: authHeader(),
            }),
            transformResponse: (response) => response.results
        })
    })
})

export const {
    useGetBestCandidatesMutation
} = candidatesApi
